import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AxiosRequestConfig } from 'axios';
import { filter, first, map, take } from 'rxjs/operators';
import { isNotNull } from 'src/app/modules/nonNullPredicate';
import { getLoginSession } from 'src/app/modules/storeModules';
import { IPatientInfo, IPCITemplate } from 'src/models';
import { Payment } from 'src/models/payment';
import { Delivery, ReservationForList } from 'src/models/pci';
import { IQAT } from 'src/models/qa-template';
import { DeliveryMethodPiPe } from 'src/pipes/delivery_method.pipe';
import { DeliveryStatusPiPe } from 'src/pipes/delivery_status.pipe';
import { PaymentStatusPipe } from 'src/pipes/payment_status.pipe';
import { SettlementMethodPipe } from 'src/pipes/settlement_method.pipe';
import { PaymentService } from 'src/services/api/payment.service';
import { PCITemplateService } from 'src/services/api/pci-template.service';
import { PciService } from 'src/services/api/pci.service';
import { PharmacistService } from 'src/services/api/pharmacist.service';
import { CognitoService } from 'src/services/cognito.service';

@Component({
  selector: 'app-reservation-detail',
  templateUrl: './reservation-detail.component.html',
  styleUrls: ['./reservation-detail.component.scss'],
})
export class ReservationDetailComponent implements OnInit {
  pci!: ReservationForList;
  patientInfo?: IPatientInfo;
  pharmacistId = '';
  displayedColumns: string[] = ['timeText', 'patientName', 'pharmacistName', 'wasConfirmed'];

  name = '';

  loading = false;
  saveQa = false;

  qaAnswer: IQAT[] = [];
  viewType: 'default' | 'text' = 'default';

  selectTemplateGroup = new FormGroup({
    template: new FormControl('', [
      Validators.required
    ]),
  });


  // 決済関連
  settlementMethodSelection: '' | 'credit_card' | 'others' = '';
  readonly coveredPriceForm = new FormControl('', Validators.pattern('.*[0-9０-９]'));
  readonly uncoveredPriceForm = new FormControl('', Validators.pattern('.*[0-9０-９]'));
  readonly otherPriceForm = new FormControl('', Validators.pattern('.*[0-9０-９]'));
  readonly shippingFeeForm = new FormControl('', Validators.pattern('.*[0-9０-９]'));
  readonly memoForm = new FormControl('');

  pciTemplates: IPCITemplate[] = [];
  selectedTemplate: IPCITemplate | undefined;

  private payments: Payment[] = [];

  // 配送関連
  deliveryMethodSelection: '' | 'normal' | 'collect_on_delivery' | 'drop_by' = '';
  memoForPharmacist: string | undefined;
  messageForPatient: string | undefined;

  get canTemplateChange(): boolean {
    return (
      this.pci?.status === 'confirmed'
    );
  }

  get canSaveQA(): boolean {
    return (
      (this.pci?.status === 'confirmed' || this.pci?.status === 'meeting_performed' || this.pci?.status === 'meeting_completed' || this.pci?.status === 'completed')
    );
  }

  get canDisplayQA(): boolean {
    return (
      (this.pci?.status === 'confirmed' || this.pci?.status === 'meeting_performed' || this.pci?.status === 'meeting_completed' || this.pci?.status === 'completed')
    );
  }

  get canCancelMeeting(): boolean {
    return (
      this.pci?.status === 'confirmed'
    );
  }

  get canStartMeeting(): boolean {
    return (
      (this.pci?.status === 'confirmed' || (this.pci?.status === 'meeting_performed' && this.isPharmacistInCharge)) &&
      !!this.pci?.pci_questions_template_id
    );
  }

  get canForceEndMeeting(): boolean {
    return false;
  }

  get isMeetingOver(): boolean {
    return this.pci?.status === 'meeting_completed';
  }

  get canDisplayPayment(): boolean {
    return this.pci?.status === 'meeting_completed' || this.pci?.status === 'completed';
  }

  get isPharmacistInCharge(): boolean {
    return this.pci.pharmacistId === this.pharmacistId || !this.pci.pharmacistId;
  }

  get canCreatePayment(): boolean {
    return this.payments.length === 0 || !this.payments.some(p => p.status !== 'cancelled');
  }

  get canCreateDelivery(): boolean {
    return !this.delivery;
  }

  get wasDecidedDeliveryMethod(): boolean {
    return !!this.pci.original.delivery;
  }

  get payment(): Payment | null {
    return this.payments.find(p => p.status !== 'cancelled') ?? null;
  }

  get paymentStatusText(): string {
    return this.paymentStatusPipe.transform(this.payment?.status ?? '');
  }

  get delivery(): Delivery | undefined {
    return this.pci.original.delivery;
  }

  get deliveryMethodText(): string {
    return this.deliveryMethodPipe.transform(this.delivery?.method ?? '');
  }

  get deliveryStatusText(): string {
    return this.deliveryStatusPipe.transform(this.delivery?.status ?? '');
  }

  get settlementMethodText(): string {
    return this.settlementMethodPipe.transform(this.payment?.settlement_method ?? '');
  }

  get isValidPayment(): boolean {
    return this.settlementMethodSelection !== '' && ([
      this.coveredPriceForm,
      this.uncoveredPriceForm,
    ].every(c => c.valid)) && (
        this.shippingFeeForm.valid ||
        this.deliveryMethodSelection === 'collect_on_delivery' || this.deliveryMethodSelection === 'drop_by'
      );
  }

  get isValidDelivery(): boolean {
    return this.deliveryMethodSelection !== '';
  }

  get isValidComplete(): boolean {
    return (this.payment !== null || this.isValidPayment) && (this.delivery !== undefined || this.isValidDelivery);
  }

  get isShippingFeeRequired(): boolean {
    return (this.delivery === undefined && (this.deliveryMethodSelection === 'normal' || this.deliveryMethodSelection === '')) || (this.delivery?.method === 'normal');
  }

  get isCompleted(): boolean {
    return this.pci?.status === 'completed';
  }

  get boxClass(): string {
    return this.canDisplayPayment ? 'boxes-wrapper' : 'boxes-wrapper-qaonly';
  }

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private store: Store,
    private pciService: PciService,
    private cognito: CognitoService,
    private pharmacistsService: PharmacistService,
    private paymentService: PaymentService,
    private paymentStatusPipe: PaymentStatusPipe,
    private deliveryMethodPipe: DeliveryMethodPiPe,
    private deliveryStatusPipe: DeliveryStatusPiPe,
    private settlementMethodPipe: SettlementMethodPipe,
    private pciTemplateService: PCITemplateService,
  ) { }

  async ngOnInit() {
    try {
      this.loading = true;
      this.pharmacistId = await getLoginSession(this.store)
        .pipe(
          filter(isNotNull),
          first(),
          map(s => s.pharmacist?.id ?? ''),
        )
        .toPromise();
      this.pci = await this.fetchPci();

      this.pciTemplates = await this.pciTemplateService.findAll();
      if (this.pci?.status === 'confirmed' && !this.pci.pci_questions_template_id) {
        const defaultTemplate = this.pciTemplates.find(template => template.name === 'default')
        this.selectedTemplate = defaultTemplate ? defaultTemplate : this.pciTemplates[0];
        this.pci.pci_questions_template_id = this.selectedTemplate.id;
        await this.pciService.specifyPciTemplate(this.pci, this.selectedTemplate);
        this.qaAnswer = [];
      } else {
        this.qaAnswer = this.pci.pci_answers ?? [];
        this.selectedTemplate = this.pciTemplates.find(template => template.id == this.pci.pci_questions_template_id);
      }
      this.selectTemplateGroup.controls['template'].setValue(this.selectedTemplate);

      this.payments = await this.fetchPayments();
      /*
      if (this.payments) {
        this.settlementMethodSelection = this.payment?.settlement_method ?? '';
        this.coveredPriceForm.setValue(String(this.payment?.settlement_amount.insurance_covered_price));
        this.uncoveredPriceForm.setValue(String(this.payment?.settlement_amount.insurance_uncovered_price));
        this.otherPriceForm.setValue(String(this.payment?.settlement_amount.other_price));
        this.shippingFeeForm.setValue(String(this.payment?.settlement_amount.shipping_fee));
        this.memoForm.setValue(this.payment?.memo);
      }
      */
    } finally {
      this.loading = false;
    }
  }

  myGroup = new FormGroup({
    pciTemplateControl: new FormControl(this.pciTemplates[0], [Validators.required]) //I'm assigining Cat by using this.animals[1], you can put any value you like as default.
  });


  compareThem(o1: { name: any; }, o2: { name: any; }): boolean {
    console.log('compare with');
    return o1.name === o2.name;
  }

  changeTemplate(event: MatSelectChange) {
    console.log(event.value);
    this.qaAnswer = event.value.qa;
    this.changePciTemplate();
  }

  public async changePciTemplate() {
    try {
      this.loading = true;
      if (!this.pci) {
        throw new Error('pciが取得できませんでした。');
      }
      await this.pciService.specifyPciTemplate(this.pci, this.selectedTemplate!);
    } catch (error) {
      alert('服薬指導フォーマットの指定に失敗しました。');
      throw error;
    } finally {
      this.loading = false;
    }
  }


  async fetchPci() {
    const data = await this.pciService.find(this.route.snapshot.paramMap.get('pciId') ?? '');
    const pharmacists = await this.pharmacistsService.findAll();
    if (data === null) {
      throw new Error('fetch pci failed');
    }
    this.patientInfo = data.patient_info;
    return new ReservationForList(data, pharmacists);
  }

  async cancel() {
    if (!confirm(`患者名:${this.pci.patientName} の服薬指導をキャンセルします。よろしいですか？`)) {
      return;
    }
    try {
      this.loading = true;
      const config: AxiosRequestConfig = {};
      if (!config.headers) {
        config.headers = {};
      }
      const token = await this.cognito.getAccessToken();
      config.headers.Authorization = token.getJwtToken();
      config.headers['Content-Type'] = 'application/json';
      await this.pciService.cancel(this.pci.id, config);
      this.router.navigate(['/pharmacist/reservation']);
    } catch (error) {
      alert('予約のキャンセルに失敗しました');
    } finally {
      this.loading = false;
    }
  }

  implementation() {
    this.router.navigate([`/pharmacist/reservation/${this.pci.id}/implementation`]);
  }

  specifyPciTemplate() {
    this.router.navigate([`/pharmacist/reservation/${this.pci.id}/pci-templates`]);
  }

  async savePciQa() {
    this.saveQa = true;
    const message = `服薬指導QAを保存します。よろしいですか？`;
    if (confirm(message)) {
      if (!this.pci) {
        throw new Error('pciが取得できませんでした。');
      }
      await this.pciService.updatePciAnswer(this.pci, this.qaAnswer);
    }
    this.loading = true;
    this.pci = await this.fetchPci();
    this.qaAnswer = this.pci.pci_answers ?? [];
    this.saveQa = false;
    this.loading = false;
  }

  async createPayment() {
    if (this.settlementMethodSelection === '') {
      alert('支払方法を入力してください！');
      return;
    }
    await this.paymentService.create({
      pci_id: this.pci.id,
      patient_account_id: this.pci.patientAccountId,
      patient_info_id: this.pci.patientInfoId,
      settlement_method: this.settlementMethodSelection,
      settlement_amount: {
        insurance_covered_price: Number(this.zenToHan(this.coveredPriceForm.value)),
        insurance_uncovered_price: Number(this.zenToHan(this.uncoveredPriceForm.value)),
        other_price: 0,
        shipping_fee: Number(this.zenToHan(this.shippingFeeForm.value)),
      },
      memo: this.memoForm.value,
      payment_completed_date: 0,
    });
    this.payments = await this.fetchPayments();
    alert('支払情報を登録しました');
  }

  zenToHan(zen: string): string {
    return zen.replace(/[０-９]/g, function (s) {
      return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
    });
  }

  async complete() {
    if (this.payment === null && this.settlementMethodSelection === '') {
      alert('支払方法を入力してください！');
      return;
    }
    if (this.delivery === undefined && this.deliveryMethodSelection === '') {
      alert('配送方法を入力してください！');
      return;
    }
    if (this.settlementMethodSelection === 'credit_card') {
      const total_price =
        Number(this.zenToHan(this.coveredPriceForm.value)) +
        Number(this.zenToHan(this.uncoveredPriceForm.value)) +
        (!this.isShippingFeeRequired ? 0 : Number(this.zenToHan(this.shippingFeeForm.value)));
      if (total_price === 0) {
        alert('料金の合計が0円の場合は支払方法にクレジットカードは選べません。');
        return;
      }
    }
    const message = `服薬指導を完了します。よろしいですか？`;
    if (confirm(message)) {
      this.loading = true;
      // 支払い情報登録
      if (this.payment === null && this.settlementMethodSelection !== '') {
        await this.paymentService.create({
          pci_id: this.pci.id,
          patient_account_id: this.pci.patientAccountId,
          patient_info_id: this.pci.patientInfoId,
          settlement_method: this.settlementMethodSelection,
          settlement_amount: {
            insurance_covered_price: Number(this.zenToHan(this.coveredPriceForm.value)),
            insurance_uncovered_price: Number(this.zenToHan(this.uncoveredPriceForm.value)),
            other_price: 0,
            shipping_fee: !this.isShippingFeeRequired ? 0 : Number(this.zenToHan(this.shippingFeeForm.value)),
          },
          memo: '',
          payment_completed_date: 0,
          delivery_method: this.deliveryMethodSelection,
        });
        this.payments = await this.fetchPayments();
      }

      //配送情報の登録
      if (this.delivery === undefined && this.deliveryMethodSelection !== '') {
        await this.pciService.postDeliveryInfo(this.pci.id, {
          method: this.deliveryMethodSelection,
          memo_for_pharmacist: '',
          //message_to_patient: this.messageForPatient!,
        });
      }

      // 服薬指導の完了
      const config: AxiosRequestConfig = {};
      config.headers = {};
      const token = await this.cognito.getAccessToken();
      config.headers.Authorization = token.getJwtToken();
      config.headers['Content-Type'] = 'application/json';
      await this.pciService.updatePciAnswer(this.pci, this.qaAnswer);
      await this.pciService.complete(this.pci.id, config);
      this.pci = await this.fetchPci();
      this.loading = false;
    }
  }

  async forceEndMeeting() {
    const message = `このボタンを押すと、現在行われているビデオ電話がある場合は、強制的に終了されます。よろしいですか？`;
    if (confirm(message)) {
      const config: AxiosRequestConfig = {};
      config.headers = {};
      const token = await this.cognito.getAccessToken();
      config.headers.Authorization = token.getJwtToken();
      config.headers['Content-Type'] = 'application/json';
      await this.pciService.forceEndMeeting(this.pci.id, config);
      this.pci = await this.fetchPci();
    }
  }

  toggleViewType(newValue: 'default' | 'text') {
    this.viewType = newValue;
  }

  fetchPayments() {
    return this.paymentService.findAll({ pci_id: this.pci.id });
  }

  async postDeliveryInfo() {
    if (this.deliveryMethodSelection === '') {
      alert('配送方法を入力してください！');
      return;
    }

    this.loading = true;
    try {
      await this.pciService.postDeliveryInfo(this.pci.id, {
        method: this.deliveryMethodSelection,
        memo_for_pharmacist: this.memoForPharmacist!,
        //message_to_patient: this.messageForPatient!,
      });
      alert('配送情報を登録しました');
      this.pci = await this.fetchPci();
    } finally {
      this.loading = false;
    }
  }

  async shipped() {
    this.loading = true;
    try {
      await this.pciService.shipped(this.pci.id);
      alert('交付済みにしました。');
      this.pci = await this.fetchPci();
    } finally {
      this.loading = false;
    }
  }

  async unshipped() {
    this.loading = true;
    try {
      await this.pciService.unshipped(this.pci.id);
      alert('未交付にしました。');
      this.pci = await this.fetchPci();
    } finally {
      this.loading = false;
    }
  }
}
